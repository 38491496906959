import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  customerAddressList,
  customerAddressCreate,
  customerAddressUpdate,
  customerShippingList,
  customerAddressDelete,
} from "redux/service/menuService/customerService/addressService";

const initialState = {
  customerAddressListRes: { data: [], isFetching: true, error: null },
  customerShippingListRes: { data: [], isFetching: true, error: null },
  customerAddressCreateRes: { data: [], isFetching: true, error: null },
  customerAddressUpdateRes: { data: [], isFetching: true, error: null },
  customerAddressDeleteRes: { data: [], isFetching: true, error: null },
};

export const customerAddressSlice = createSlice({
  name: "customerAddressSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: customerAddressList, name: "customerAddressListRes" },
      { api: customerShippingList, name: "customerShippingListRes" },
      { api: customerAddressCreate, name: "customerAddressCreateRes" },
      { api: customerAddressUpdate, name: "customerAddressUpdateRes" },
      { api: customerAddressDelete, name: "customerAddressDeleteRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = customerAddressSlice.actions;
