export const createAsyncActionHandlers = (builder, asyncAction) => {
  builder
    .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
      state[asyncAction.name].isFetching = false;
      state[asyncAction.name].data = payload;
    })
    .addCase(asyncAction.api.pending, (state) => {
      state[asyncAction.name].isFetching = true;
      state[asyncAction.name].error = null;
    })
    .addCase(asyncAction.api.rejected, (state, { payload }) => {
      state[asyncAction.name].isFetching = false;
      state[asyncAction.name].error = payload;
    });
};
