import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// categories List
export const categoriesList = createAsyncThunk(
  "categories-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/categories`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// single categories
export const singleCategory = createAsyncThunk(
  "category-single",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/category/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Categories Create
export const categoriesCreate = createAsyncThunk(
  "categories-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/category`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Categories Edit
export const categoriesEdit = createAsyncThunk(
  "categories-edit",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/category/${bodyParams.id}`,
        bodyParams.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Categories Delete
export const categoriesDelete = createAsyncThunk(
  "categories-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/category/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const categorystatusUpdate = createAsyncThunk(
  "category-status",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/categorystatus/${bodyParams.id}`,
        bodyParams
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
