import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// temp-product List
export const tempProductList = createAsyncThunk(
  'temp-product-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/temporaryproducts`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// temp-product Delete
export const tempProductDelete = createAsyncThunk(
  'temp-product-delete',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/temporaryproduct/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// temp-product Approve
export const tempProductApprove = createAsyncThunk(
  'temp-product-Approve',
  async ({ approveId, formData }, thunkAPI) => {
    try {
      const response = await API.put(`/temporaryproducts/${approveId}`, formData);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// temp-product Edit
export const tempProductSingleView = createAsyncThunk(
  'temp-product-single-view',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/temporaryproduct/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

