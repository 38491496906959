import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";

// price DashBoard update 
export const priceDashProductUpdate = createAsyncThunk(
  "price-dashboard-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(`/productUpdateInPriceDashboard`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);