import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//packing-list
export const packingList = createAsyncThunk(
  "packing-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`orders`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
