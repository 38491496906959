import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//pos-list
export const posSummary = createAsyncThunk(
  "pos-cash-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/transactionSummaryforalldevices`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//pos-transaction-list
export const posTransactionSummary = createAsyncThunk(
  "pos-transaction-summary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/gettransactions`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//pos-download
export const downloadAllTypeReport = createAsyncThunk(
  "report-download",
  async ({ name, param }, thunkAPI) => {
    try {
      const response = await API.get(`/reportsdownload/${name}`, {
        responseType: "blob",
        params: param,
      }).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.${param.doc_type}`;
        a.click();
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//pos-transaction-list
export const posCurrentTotalSummary = createAsyncThunk(
  "pos-currentTotal-summary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/getCurrentCashTotals/${bodyParams.device_id}`,
        {
          params: bodyParams,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//pos-transaction-list
export const posCurrentCashSummary = createAsyncThunk(
  "pos-currentcash-summary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/getCurrentCash/${bodyParams.device_id}`,
        {
          params: bodyParams,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//pos-available-cash 
export const posAvailableCashSummary = createAsyncThunk(
  "pos-availablecash-summary-list",
  async (bodyParams, thunkAPI) => {
    
    try {
      const response = await API.get(
        `/getAvailableCash/${bodyParams.device_id}`,
        {
          params: bodyParams,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//pos-transaction-list
export const posCashInOutSummary = createAsyncThunk(
  "pos-cashinout-summary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/getCashInCashOut/${bodyParams.device_id}`,
        {
          params: bodyParams,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//pos-transaction-list
export const posTransactionsSummary = createAsyncThunk(
  "pos-transactions-summary-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/transactionSummary/${bodyParams.device_id}`,
        {
          params: bodyParams,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
