import { createStyles } from "@mantine/core";

const tabStyle = createStyles((theme) => ({
  tab: {
    fontSize: theme.fontSizes.sm,
    // display: 'flex',
    // alignItems: 'center',
    padding: theme.fontSizes.md,
    color: "#484848",

    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    minWidth: "16%",
    borderTopLeftRadius: theme.radius.md,
    borderTopRightRadius: theme.radius.md,
    height: "2.3rem",
    // '&:not(:first-of-type)': {
    //   borderLeft: 1,
    // },

    "&:first-of-type": {
      borderTopLeftRadius: theme.radius.md,
      // borderBottomLeftRadius: theme.radius.md,
    },
    "&[data-active]:hover": {
      backgroundColor: "#0094D1",
    },
    "&[data-active]": {
      backgroundColor: "#0094D1",
      borderColor: "#0094D1",
      color: "#FFFFFF",
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    mixBlendMode: "color-burn",
  },

  tabsList: {},
  panel: {},
}));

export default tabStyle;
