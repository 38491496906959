import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//credit-list
export const customerAllCreditList = createAsyncThunk(
  "customer-allcredit-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/credits`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-credit-view
export const customerCreditView = createAsyncThunk(
  "//customer-credit-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/${bodyParams.customerId}/credits/${bodyParams.id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-credit-create
export const customerCreditCreate = createAsyncThunk(
  "customer-credit-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`customer/addcredit`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-credit-update
export const customerCreditUpdate = createAsyncThunk(
  "customer-credit-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `/customer/${bodyParams.customer_id}/credit/${bodyParams.id}/approve`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// customer-credit-delete
export const customerCreditDelete = createAsyncThunk(
  "customer-credit-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `customer/${bodyParams.customerId}/credit/${bodyParams.id}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const customerCreditTermUpdate = createAsyncThunk(
  "customer-creditTerm-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `/customer/${bodyParams.customer_id}/editterm`,
        bodyParams
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendOTPforCreditOverwrite = createAsyncThunk(
  "sendotpforcreditoverwrite",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/sendotpforcreditoverwrite/${bodyParams?.customer_id}`,
        bodyParams
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//approve-creditoverwrite
export const approveCreditOverwrite = createAsyncThunk(
  "approve-creditoverwrite",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`approvecreditoverwrite`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
