import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  inventoryreportList,
  topsellingproductsList,
  lowstockproductsList,
} from "redux/service/reportService/inventoryReport";
const initialState = {
  inventoryreportListRes: { data: [], isFetching: true, error: null },
  topsellingproductsListRes: { data: [], isFetching: true, error: null },
  lowstockproductsListRes: { data: [], isFetching: true, error: null },
};

export const inventoryReportSlice = createSlice({
  name: "inventoryReportSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: inventoryreportList, name: "inventoryreportListRes" },
      { api: topsellingproductsList, name: "topsellingproductsListRes" },
      { api: lowstockproductsList, name: "lowstockproductsListRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { reportDispatch } = inventoryReportSlice.actions;
