import React, {useRef, useState} from "react";
import {Button, Flex, ScrollArea, Select, Text, Image} from "@mantine/core";
import {Controller, useController} from "react-hook-form";
import {deleteIcon} from "helpers/images";
import {IconPlus} from "@tabler/icons-react";
import ConfirmationModel from "components/popups/ConfirmationModel";
import {notify} from "../../helpers/Golbal";
import {useDispatch} from "react-redux";
import {commonEssentialListDelete} from "redux/service/essentialService/essentialService";
const AddSelectField = ({
  label,
  placeholder,
  control,
  name,
  required,
  defaultValue,
  disabled,
  searchable,
  clearable,
  variant,
  data = [],
  maxDropdownHeight,
  display,
  readOnly,
  // filter,
  icon,
  getEssential,
  rightSection,
  handleAddNewOption,
  fieldname,
  noDeleted,
  onChange,
  dropdownPosition,
  onDataChange,
}) => {
  const [searchValue, onSearchChange] = useState("");
  const [confirmModel, setConfirModel] = useState({
    model: false,
    id: null,
  });
  const dispatch = useDispatch();
  const {
    field,
    fieldState: {error},
  } = useController({
    name: name,
    control,
  });
  const otherElementRef = useRef(null);
  const handleDelete = (data) => {
    field.onChange(null);
    setConfirModel({model: true, id: data.value});
  };
  const handleDeleteRow = async () => {
    const formValues = {
      id: confirmModel.id,
      type: fieldname,
    };
    try {
      const response = await dispatch(
        commonEssentialListDelete(formValues)
      ).unwrap();
      notify(response);
      getEssential();
      confirmModel.id === field.value && field.onChange(null);
      setConfirModel({model: false, id: null});
    } catch (error) {
      notify(error);
    }
  };
  return (
    <>
      <Select
        size="xs"
        label={label}
        placeholder={placeholder}
        error={error && error.message}
        display={display}
        withAsterisk={required}
        clearable={clearable}
        {...field}
        readOnly={readOnly}
        defaultValue={defaultValue}
        searchable={searchable}
        variant={variant ?? "filled"}
        onSearchChange={onSearchChange}
        searchValue={searchValue}
        nothingFound="No options"
        dropdownPosition={dropdownPosition}
        data={data ?? []}
        disabled={disabled}
        icon={icon}
        rightSection={rightSection}
        // filter={filter}
        maxDropdownHeight={maxDropdownHeight}
        dropdownComponent={(query) => (
          <>
            <Flex
              direction={"column"}
              sx={{width: "100%", cursor: "pointer", padding: "10px"}}
            >
              <ScrollArea scrollbarSize={9} scrollHideDelay={1500}>
                {data &&
                  data?.map((dataa) => (
                    <>
                      <Flex
                        direction={"row"}
                        align={"center"}
                        justify={"space-between"}
                        px={3}
                        py={5}
                        sx={{
                          background: field.value === dataa.value && "#0d6efd",
                          borderRadius: "5px",
                          "&:hover": {
                            background:
                              field.value !== dataa.value && "#f1f3f5",
                          },
                          color: field.value === dataa.value && "#ffff",
                        }}
                        onClick={() => {
                          field.onChange(dataa.value);
                          onChange && onChange(dataa.value);
                          onDataChange && onDataChange(dataa);
                          otherElementRef.current.focus();
                        }}
                      >
                        <Text fz={"xs"}>{dataa.label}</Text>
                        {!noDeleted && (
                          <Image
                            onClick={(e) => handleDelete(dataa)}
                            width={15}
                            src={deleteIcon}
                          />
                        )}
                      </Flex>
                    </>
                  ))}
              </ScrollArea>

              <Button
                onClick={handleAddNewOption}
                variant="filled"
                color="gray"
                mih={"1.8rem"}
                miw={"1rem"}
                fullWidth
                mt={5}
                leftIcon={<IconPlus size={13} color={"white"} />}
              >
                Add {label}
              </Button>
            </Flex>
            <span tabIndex={0} ref={otherElementRef}></span>
          </>
        )}
      />
      {confirmModel.model && (
        <ConfirmationModel
          close={() => setConfirModel({...confirmModel, model: false})}
          opened={confirmModel.model}
          label={"delete"}
          handleSumbit={() => handleDeleteRow()}
        />
      )}
    </>
  );
};

export default AddSelectField;
