import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//card-create
export const cardCreate = createAsyncThunk(
  "card-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`addcards`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//card-list
export const cardList = createAsyncThunk(
  "card-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/getpaymentmethod/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//card-delete
export const cardDelete = createAsyncThunk(
  "payment-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(
        `delete/${bodyParams.customerId}/card/${bodyParams.id}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
