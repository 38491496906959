import React from "react";
import { Button, Flex, Modal } from "@mantine/core";
import { useForm } from "react-hook-form";
import DatepickerDisplay from "components/react-hook-form/DatepickerDisplay";

const DatePopup = ({ opened, close, loading, data, handleFormSubmit }) => {
  const { control, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => close()}
        centered
        size={300}
        title={"Update Delivery Date"}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <DatepickerDisplay
            name={data.name}
            label={data.label}
            control={control}
          />
          <Flex mt={"md"} justify={"flex-end"} gap={"md"}>
            <Button
              w={120}
              variant="outline"
              type="reset"
              onClick={() => {
                close();
                reset();
              }}
            >
              Cancel
            </Button>
            <Button w={120} type="submit" loading={loading}>
              ADD
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};

export default DatePopup;
