import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// purchasre order List
export const purchaseOrderList = createAsyncThunk(
  "purchase-order-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/purchaseorders`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// purchasre order List
export const purchaseBackOrderList = createAsyncThunk(
  "purchase-back-order-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/listbackpurchaseorders`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// purchasre order List
export const supplierProductsList = createAsyncThunk(
  "supplier-product-list",
  async ({ supplierId, params }, thunkAPI) => {
    try {
      const response = await API.get(`/supplierproducts/${supplierId}`, {
        params: params,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order single view
export const purchaseOrderSingleView = createAsyncThunk(
  "purchase-order-single-view",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/purchaseorder/${id}`, {
        params: params,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order Recieved single view
export const purchaseOrderRecievedSingleView = createAsyncThunk(
  "purchase-order-recieved-single-view",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/receivepoview/${id}`, {
        params: params,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// purchasre order Recieved single view
export const purchaseOrderRecievedAndCancelView = createAsyncThunk(
  "purchase-order-recieved-and-cancel-view",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(
        `/viewCancelAndReceivePurchaseOrder/${id}`,
        { params: params }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order delete
export const purchaseOrderDelete = createAsyncThunk(
  "purchase-order-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/purchaseorderitem/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order Edit
export const purchaseOrderEdit = createAsyncThunk(
  "purchase-order-edit",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/purchaseorder/${bodyParams.id}`,
        bodyParams.formData
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order Create
export const purchaseOrderCreate = createAsyncThunk(
  "purchase-order-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/purchaseorder`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order Approve
export const purchaseOrderApprove = createAsyncThunk(
  "purchase-order-approve",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/approvepurchaseorder/${bodyParams.approveId}`,
        bodyParams.data
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order cancel
export const purchaseOrderCancel = createAsyncThunk(
  "purchase-order-cancel",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/cancelpurchaseorder`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// purchase order whole cancel
export const purchaseOrderSingleCancel = createAsyncThunk(
  "purchase-order-single-cancel",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/cancelpurchaseorder/${bodyParams} `);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// purchasre order Delivery type
export const purchaseOrderDeliveryType = createAsyncThunk(
  "purchase-order-delivery-type",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/getdeliverytype/${bodyParams.supplierId}`,
        { params: bodyParams }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order Delivery type
export const purchaseOrderSku = createAsyncThunk(
  "purchase-order-sku",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/purchaseordersku`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre orderRecieved PO
export const purchaseOrderRecievedPo = createAsyncThunk(
  "purchase-order-recieve-po",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/receivepurchaseorder`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre orderRecieved PO
export const purchaseOrderCreateProcessing = createAsyncThunk(
  "purchase-order-processiung-po",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/processingpurchaseorder/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back order single view
export const purchaseOrderBackOrderSingleView = createAsyncThunk(
  "purchase-order-back-order-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/purchasebackorder/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// purchasre order List
export const getSupplierCost = createAsyncThunk(
  "supplier-cost",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/getprice`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const supplierproductcost = createAsyncThunk(
  "supplierproductcost",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/supplierproductcost/${bodyParams?.supplierId}/${bodyParams?.productId}?unit_type=${bodyParams?.unitType}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

///unapprove waiting for approve
export const unapprovewaiting = createAsyncThunk("unapprovewaiting",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/changeapprovedstatus/${bodyParams?.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)