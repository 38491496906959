import { AppShell } from "@mantine/core";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SESSION } from "../../helpers/Golbal";
import { getSessionStorage } from "../../helpers/SessionConfig";
import CustomHeader from "./Header/header";
import CustomNavbar from "./NavBar/navbar";

const Layout = () => {
  const token = () => getSessionStorage(SESSION.TOKEN);

  const [lastActive, setLastActive] = useState(new Date());
  const [isTimeout, setIsTimeout] = useState(false);
  const [closeNavbar, setCloseNavbar] = useState(true);
  const [navbarStatus, setNavbarStatus] = useState(false);
  const handleClose = (value) => setNavbarStatus(value);
  const location = useLocation();

  useEffect(() => {
    const handleActivity = () => {
      setLastActive(new Date());
    };
    // window.addEventListener('load', listener);
    // window.addEventListener('mousemove', listener);
    // window.addEventListener('mousedown', listener);
    // window.addEventListener('click', listener);
    // window.addEventListener('scroll', listener);
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("touchstart", handleActivity);

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
    };
  }, []);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setIsTimeout(true);
  //     // }, 15000); //15s to check
  //   }, 3600000); // 1 hour in milliseconds

  //   return () => clearTimeout(timeoutId);
  // }, [lastActive]);

  return token() ? (
    <AppShell
      padding="md"
      navbar={
        <CustomNavbar
          closeNavbar={closeNavbar}
          handleClose={(value) => handleClose(value)}
        />
      }
      styles={(theme) => ({
        main: { backgroundColor: "#F7F7F7" },
      })}
      sx={{
        overflow: "hidden",
      }}
    >
      <CustomHeader isTimeout={isTimeout} setIsTimeout={setIsTimeout} />
      <div
        onClick={() => {
          navbarStatus && setCloseNavbar(!closeNavbar);
        }}
        className="layout-inner-design"
      >
        <Outlet />
      </div>
    </AppShell>
  ) : (
    <Navigate to={`/login?path=${location.pathname}`} />
  );
};

export default Layout;
