import { createStyles } from "@mantine/core";

const Styles = createStyles((theme) => ({
  box: {
    // textAlign: 'center',
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    cursor: "pointer",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },
  },
  dropdown: {
    background:
      "transparent linear-gradient(180deg, #F5F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  },
  item: {
    "&[data-active]": {
      backgroundColor: "#EEF6FF",
    },
  },
  tab: {
    fontSize: theme.fontSizes.xl,
    lineHeight: "0.75rem",
    display: "flex",
    alignItems: "center",
    padding: theme.fontSizes.md,
    color: "#484848",

    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },

    "&:not(:first-of-type)": {
      borderLeft: 1,
    },

    "&:first-of-type": {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },
    "&:last-of-type": {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },
    "&[data-active]:hover": {
      backgroundColor: "#EEF6FF",
    },
    "&[data-active]": {
      backgroundColor: "#EEF6FF",
      borderColor: "#D8E5F5",
      color: "#0094D1",
    },
  },
  tabIcon: {
    marginRight: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
  },

  tabsList: {
    display: "flex",
    margin: "1%",
    width: "14%",
  },
  panel: {
    minWidth: "60%",
  },
}));

export default Styles;
