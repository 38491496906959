import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//customer-address-list
export const customerAddressList = createAsyncThunk(
  "customer-address-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/${bodyParams}/addresses/billing`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-shipping-address-list
export const customerShippingList = createAsyncThunk(
  "customer-shipping-address-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/${bodyParams}/addresses/shipping`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-address-create
export const customerAddressCreate = createAsyncThunk(
  "customer-address-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `customer/${bodyParams.customerId}/address`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-address-update
export const customerAddressUpdate = createAsyncThunk(
  "customer-address-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `/customer/${bodyParams.customerId}/address/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// customer-address-delete
export const customerAddressDelete = createAsyncThunk(
  "customer-address-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/customer/${bodyParams.customerId}/address/${bodyParams.id}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
