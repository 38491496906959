import React from "react";
import {
  Box,
  Button,
  FileInput,
  Flex,
  Grid,
  Image,
  Modal,
  Text,
} from "@mantine/core";
import {deleteIcon, downloadIcon, uploadIcon} from "helpers/images";
import {Controller, useForm} from "react-hook-form";
import {handleDownload} from "helpers/download";
import {notify} from "helpers/Golbal";

const MultipleDocumentPopup = ({
  opened,
  close,
  handleUploadDocments,
  initialValue = [],
  name,
  handleDeletedDocument,
  readOnly,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      [name]: initialValue,
    },
  });

  const documents = watch(name);

  const handleImages = (data) => {
    const maxSizeInBytes = 2 * 1024 * 1024;
    const newImages = Array.from(data).filter((file) =>
      file.size <= maxSizeInBytes
        ? file.size <= maxSizeInBytes
        : notify({
            success: false,
            message: "Document must upto 2MB",
          })
    );
    // const newImages = Array.from(data).map((file) => file);
    setValue(name, [...documents, ...newImages]);
  };

  const handleDeleteImage = (index, id) => {
    const updatedImages = documents.filter((_, i) => i !== index);
    setValue(name, updatedImages);
    id && handleDeletedDocument(id);
  };

  return (
    <>
      <form>
        <Modal opened={opened} onClose={() => close()} title="Upload " centered>
          <Grid>
            <Grid.Col md={12}>
              <Controller
                control={control}
                name={name}
                render={({field}) => (
                  <>
                    <FileInput
                      {...field}
                      value={field.value}
                      size="xs"
                      type="submit"
                      onChange={(data) => {
                        handleImages(data);
                      }}
                      readOnly={readOnly}
                      placeholder="Upload Document upto 2mb"
                      multiple
                      icon={<Image src={uploadIcon} width={"15px"} />}
                      variant="filled"
                      accept="image/*,application/pdf"
                    />
                  </>
                )}
              />
            </Grid.Col>
            {documents &&
              documents?.map((data, index) => (
                <Grid.Col md={6} key={index}>
                  <Box
                    p={3}
                    sx={{
                      border: `1px solid #DDDDDD`,
                      borderRadius: "5px",
                      ":hover": {
                        backgroundColor: "#e9ecef",
                      },
                    }}
                  >
                    <Flex gap={10} justify={"space-between"} align={"center"}>
                      <Text fz={"xs"} lineClamp={1}>
                        {data.name ?? `Document ${index + 1}`}
                      </Text>
                      <Flex direction={"row"} gap={5}>
                        {!readOnly && (
                          <Image
                            className="pointer"
                            onClick={() => {
                              handleDeleteImage(index, data?.id);
                            }}
                            width={"1rem"}
                            src={deleteIcon}
                          />
                        )}
                        <Image
                          className="pointer"
                          onClick={() =>
                            handleDownload(
                              data.document
                                ? `${process.env.REACT_APP_API_IMAGE_ENDPOINT}${data.document}`
                                : URL.createObjectURL(data)
                            )
                          }
                          width={"1rem"}
                          src={downloadIcon}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </Grid.Col>
              ))}
            <Grid.Col>
              <Button
                onClick={handleSubmit(handleUploadDocments)}
                type="submit"
              >
                Save
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </form>
    </>
  );
};

export default MultipleDocumentPopup;
