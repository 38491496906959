import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  ImageUploadPost,
  ImageDocUploadPost,
  ImageDocUploadView,
  ImageDocUploadDelete,
  SupplierImageDocUploadDelete,
} from "redux/service/commonService/imageService";
const initialState = {
  CommonSelectDeleteRes: { data: [], isFetching: false, error: null },
  ImageUploadPostRes: { data: [], isFetching: false, error: null },
  ImageDocUploadPostRes: { data: [], isFetching: false, error: null },
  ImageDocUploadViewRes: { data: [], isFetching: true, error: null },
  ImageDocUploadDeleteRes: { data: [], isFetching: true, error: null },
  SupplierImageDocUploadDeleteRes: { data: [], isFetching: true, error: null },
};
// common slice
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: ImageUploadPost, name: "ImageUploadPostRes" },
      { api: ImageDocUploadPost, name: "ImageDocUploadPostRes" },
      { api: ImageDocUploadView, name: "ImageDocUploadViewRes" },
      { api: ImageDocUploadDelete, name: "ImageDocUploadDeleteRes" },
      {
        api: SupplierImageDocUploadDelete,
        name: "SupplierImageDocUploadDeleteRes",
      },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { commonDispatch } = commonSlice.actions;
