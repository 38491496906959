import { createSlice } from "@reduxjs/toolkit";
import { priceWatchCreateDelete, priceWatchList } from "redux/service/menuService/priceWatchService";

const initialState = {
  priceWatchCreateRes: { data: [], isFetching: false, error: null },
  priceWatchListRes: { data: [], isFetching: true, error: null },
};

export const pricingWatchSlice = createSlice({
  name: "pricingWatchSlice",
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: priceWatchCreateDelete, name: "priceWatchCreateRes" },
      { api: priceWatchList, name: "priceWatchListRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { pricingWatchDispatch } = pricingWatchSlice.actions;