import { createSlice } from '@reduxjs/toolkit'
import { brandCreate, brandDelete, brandEdit, brandList, brandStatus, singleBrand } from 'redux/service/menuService/brandService'


const initialState = {
  brandListRes: { data: [], isFetching: false, error: null },
  brandCreateRes: { data: [], isFetching: false, error: null },
  singleBrandRes: { data: [], isFetching: false, error: null },
  brandEditRes: { data: [], isFetching: false, error: null },
  brandDeleteRes: { data: [], isFetching: false, error: null },
  brandStatusRes: { data: [], isFetching: false, error: null },
}

export const brandSlice = createSlice({
  name: 'brandSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: brandCreate, name: "brandCreateRes" },
      { api: brandDelete, name: "brandDeleteRes" },
      { api: brandEdit, name: "brandEditRes" },
      { api: brandList, name: "brandListRes" },
      { api: brandStatus, name: "brandStatusRes" },
      { api: singleBrand, name: "singleBrandRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { brandDispatch } = brandSlice.actions
