import { Textarea } from "@mantine/core";
import React from "react";
import { Controller } from "react-hook-form";

const TextAreaField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  disabled,
  formatCapital,
  error,
  type,
  maxLength,
  prefix,
  readOnly,
  widthDiffer,
  isChangestyle,
  maxRows,
  minRows,
}) => {
  const customStyles = {
    width: "500px",
  };
  const determineStyle = () => {
    if (widthDiffer === "commentsWidth") {
      return customStyles;
    }
    return undefined;
  };
  return (
    <>
      <>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Textarea
              placeholder={placeholder}
              size={"xs"}
              label={label}
              variant="filled"
              withAsterisk={required}
              error={error && error.message}
              {...field}
              readOnly={readOnly}
              minRows={minRows}
              maxRows={maxRows}
              style={determineStyle()}
              sx={{
                textarea: isChangestyle,
              }}
              onBlur={() => {
                if (
                  formatCapital &&
                  field.value &&
                  typeof field.value === "string" &&
                  field.value.length > 0
                ) {
                  const updatedValue =
                    field.value[0].toUpperCase() + field.value.substring(1);
                  field.onChange(updatedValue);
                }
              }}
              // onBlur={() =>
              //   formatCapital &&
              //   field.value &&
              //   field.onChange(
              //     field?.value[0]?.toUpperCase() + field?.value?.substring(1)
              //   )
              // }
            />
          )}
        />
      </>
    </>
  );
};

export default TextAreaField;
