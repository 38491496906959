import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Role Create
export const addRole = createAsyncThunk(
  'add-role',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/role`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Role Default
export const defaultRole = createAsyncThunk(
  'default-role',
  async (_bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/defaultRole`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Role  List 
export const listRole = createAsyncThunk(
  'list-role',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/roles`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// view single Role
export const singleRoleView = createAsyncThunk(
  'single-role',
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/role/${id}`, { params });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Edit Role
export const editRole = createAsyncThunk(
  'edit-role',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/role/${bodyParams.id}`, bodyParams.formData,);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Delete Role
export const deleteRole = createAsyncThunk(
  'delete-role',
  async (params, thunkAPI) => {
    try {
      const response = await API.put(`/role/${params}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })





