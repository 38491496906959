import { createSlice } from '@reduxjs/toolkit'
import {
  addStoreSetting,
  deleteWebsite,
  listWebsiteStore,
  singleWebsiteView,
  websiteSetting,
  popularProductSetting,
  bannerStoreSetting,
  flyerStoreSetting,
} from '../../service/settingsService/storeSettingsService'


const initialState = {
  addStoreSettingRes: { data: [], isFetching: false, error: null },
  listWebsiteStoreRes: { data: [], isFetching: false, error: null },
  singleWebsiteViewRes: { data: [], isFetching: false, error: null },
  deleteWebsiteViewRes: { data: [], isFetching: false, error: null },
  websiteStoreSettingRes: { data: [], isFetching: false, error: null },
  popularProductSettingRes: { data: [], isFetching: false, error: null },
  bannerStoreSettingRes: {data: [], isFetching: false, error: null},
  flyerStoreSettingRes: {data: [], isFetching: false, error: null}
}

export const storeSettingsSlice = createSlice({
  name: 'storeSettingsSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: addStoreSetting, name: "addStoreSettingRes" },
      { api: deleteWebsite, name: "deleteWebsiteViewRes" },
      { api: listWebsiteStore, name: "listWebsiteStoreRes" },
      { api: singleWebsiteView, name: "singleWebsiteViewRes" },
      { api: websiteSetting, name: "websiteStoreSettingRes" },
      { api: popularProductSetting, name: "popularProductSettingRes" },
      {api: bannerStoreSetting, name: "bannerStoreSettingRes"},
      {api: flyerStoreSetting, name: "flyerStoreSettingRes"}
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { storeSettingsDispatch } = storeSettingsSlice.actions
