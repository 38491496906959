import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  unpaidCreditList,
  receivableCreate,
  paymentpendingView,
  receivePayment,
  ctsAirPayCreate,
  ctsairpayConfirm,
  receivePaymentList,
  overPaymentList,
  splitPaymentCreate,
  refundCreate,
  referenceGet,
  unappliedList,
  overwriteList,
} from "redux/service/menuService/customerService/receivableService";

const initialState = {
  unpaidCreditListRes: { data: [], isFetching: false, error: null },
  receivableCreateRes: { data: [], isFetching: false, error: null },
  paymentpendingViewRes: { data: [], isFetching: false, error: null },
  receivePaymentRes: { data: [], isFetching: false, error: null },
  ctsAirPayCreateRes: { data: [], isFetching: false, error: null },
  ctsairpayConfirmRes: { data: [], isFetching: false, error: null },
  receivePaymentListRes: { data: [], isFetching: true, error: null },
  overPaymentListRes: { data: [], isFetching: true, error: null },
  splitPaymentCreateRes: { data: [], isFetching: false, error: null },
  refundCreateRes: { data: [], isFetching: false, error: null },
  referenceGetRes: { data: [], isFetching: false, error: null },
  unappliedListRes: { data: [], isFetching: false, error: null },
  overwriteListRes: { data: [], isFetching: false, error: null },
};

export const receivableSlice = createSlice({
  name: "receivableSlice",
  initialState,
  reducers: {
    resetPaymentpendingViewRes: (state) => {
      state.paymentpendingViewRes = initialState.paymentpendingViewRes;
      state.receivableCreateRes = initialState.receivableCreateRes;
    },
  },
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: unpaidCreditList, name: "unpaidCreditListRes" },
      { api: receivableCreate, name: "receivableCreateRes" },
      { api: paymentpendingView, name: "paymentpendingViewRes" },
      { api: receivePayment, name: "receivePaymentRes" },
      { api: ctsAirPayCreate, name: "ctsAirPayCreateRes" },
      { api: ctsairpayConfirm, name: "ctsairpayConfirmRes" },
      { api: receivePaymentList, name: "receivePaymentListRes" },
      { api: overPaymentList, name: "overPaymentListRes" },
      { api: splitPaymentCreate, name: "splitPaymentCreateRes" },
      { api: refundCreate, name: "refundCreateRes" },
      { api: referenceGet, name: "referenceGetRes" },
      { api: unappliedList, name: "unappliedListRes" },
      { api: overwriteList, name: "overwriteListRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch, resetPaymentpendingViewRes } = receivableSlice.actions;

export default receivableSlice.reducer;
