import { createSlice } from '@reduxjs/toolkit'
import {
  billList, billSingleView, changeAllProductCost,
  completeSingleBill,
  createBill, deleteBill, editBill
} from 'redux/service/menuService/billService';


const initialState = {
  billListRes: { data: [], isFetching: false, error: null },
  createBillRes: { data: [], isFetching: false, error: null },
  billSingleViewRes: { data: [], isFetching: false, error: null },
  editBillRes: { data: [], isFetching: false, error: null },
  deleteBillRes: { data: [], isFetching: false, error: null },
  changeAllProductCostRes: { data: [], isFetching: false, error: null },
  completeSingleBillRes: { data: [], isFetching: false, error: null },
}

export const billSlice = createSlice({
  name: 'billSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: billList, name: "billListRes" },
      { api: createBill, name: "createBillRes" },
      { api: billSingleView, name: "billSingleViewRes" },
      { api: editBill, name: "editBillRes" },
      { api: deleteBill, name: "deleteBillRes" },
      { api: changeAllProductCost, name: "changeAllProductCostRes" },
      { api: completeSingleBill, name: "completeSingleBillRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { billDispatch } = billSlice.actions