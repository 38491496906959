import React from "react";
import {Table, Box, Text, Skeleton} from "@mantine/core";

function NoTableDataComponent({columns = [], text, loading}) {
  return (
    <Box w={"100%"}>
      <Table className="custom-table">
        <thead
          style={{
            backgroundColor: "#E2EEFA",
            minHeight: "33px",
          }}
        >
          <tr>
            {columns?.map((data, i) => (
              <th style={{width: data?.width, fontWeight: 500}}>
                {data?.name}
              </th>
            ))}
          </tr>
        </thead>
      </Table>
      {loading && (
        <Table className="listDataTable ">
          <tbody className="">
            {columns?.map((data, i) => (
              <tr>
                <td style={{width: data?.width}}>
                  <Skeleton height={8} radius="xl" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Text
        my={10}
        align="center"
        sx={{
          minHeight: "30vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {text}
      </Text>
    </Box>
  );
}

export default NoTableDataComponent;
