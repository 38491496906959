import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";

// price watch create 
export const priceWatchCreateDelete = createAsyncThunk(
  "priceWatch-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/pricewatch`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// price watch list 
export const priceWatchList = createAsyncThunk(
  "priceWatch-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productslists`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);