import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  userList,
  userCreate,
  userDelete,
  userUpdate,
} from "redux/service/menuService/customerService/UserService";

const initialState = {
  userCreateRes: { data: [], isFetching: false, error: null },
  userListRes: { data: [], isFetching: true, error: null },
  userDeleteRes: { data: [], isFetching: false, error: null },
  userUpdateRes: { data: [], isFetching: false, error: null },
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: userCreate, name: "userCreateRes" },
      { api: userList, name: "userListRes" },
      { api: userDelete, name: "userDeleteRes" },
      { api: userUpdate, name: "userUpdateRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = userSlice.actions;
