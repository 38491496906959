import * as yup from "yup";
const productSchema = yup.object().shape({
  name: yup.string().required("Required"),
  category_id: yup.number().required("Required"),
  unit_id: yup.string().required("Required"),
  qty: yup.string().required("Required"),
  supplier_id: yup.number().required("Required"),
  // cost: yup.number().typeError("Required").min(1, 'Price must be greater than 0').required("Required"),
  cost: yup.number().required("Required"),
  regular_percentage: yup.number().required("Required"),
  order_type: yup.string().required("Required"),
});
export default productSchema;
