import { createStyles } from '@mantine/core';


const modelStyle = createStyles((theme) => ({
  header: {
    background: "#F0F0F0 0% 0% no-repeat padding-box",
    height: '25px'
  }
}))

export default modelStyle