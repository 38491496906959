import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  commonEssentialListDelete,
  downloadView,
  singledownloadView,
  essentialAddTax,
  essentialCategoriesList,
  essentialCategoryProductList,
  essentialCategoryShortcutList,
  essentialCustomerList,
  essentialList,
  essentialListAdd,
  essentialMultipleDocumentDelete,
  essentialMultipleImageDelete,
  essentialProductList,
  getCsvToJson,
  priceGroupSingleCustomer,
  sendEmail,
  essentialPricingGroupCustomerList,
} from "redux/service/essentialService/essentialService";

const initialState = {
  essentialCategoriesListRes: { data: [], isFetching: true, error: null },
  essentialListRes: { data: [], isFetching: true, error: null },
  essentialMultipleImageDeleteRes: { data: [], isFetching: true, error: null },
  essentialMultipleDocumentDeleteRes: {
    data: [],
    isFetching: true,
    error: null,
  },
  essentialAddTaxRes: { data: [], isFetching: false, error: null },
  essentialProductListRes: { data: [], isFetching: false, error: null },
  essentialCustomerListRes: { data: [], isFetching: false, error: null },
  commonEssentialListDeleteRes: { data: [], isFetching: false, error: null },
  essentialCategoryProductListRes: { data: [], isFetching: false, error: null },
  downloadViewRes: { data: [], isFetching: false, error: null },
  sendEmailRes: { data: [], isFetching: false, error: null },
  essentialListAddRes: { data: [], isFetching: false, error: null },
  priceGroupSingleCustomerRes: { data: [], isFetching: false, error: null },
  essentialCategoryShortcutListRes: { data: [], isFetching: true, error: null },
  getCsvToJsonRes: { data: [], isFetching: false, error: null },
  essentialPricingGroupCustomerListRes: {
    data: [],
    isFetching: false,
    error: null,
  },
  singledownloadViewRes: { data: [], isFetching: false, error: null },
};

export const essentialSlice = createSlice({
  name: "essentialSlice",
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      {
        api: essentialPricingGroupCustomerList,
        name: "essentialPricingGroupCustomerListRes",
      },
      { api: essentialAddTax, name: "essentialAddTaxRes" },
      { api: essentialCategoriesList, name: "essentialCategoriesListRes" },
      { api: essentialList, name: "essentialListRes" },
      {
        api: essentialMultipleDocumentDelete,
        name: "essentialMultipleDocumentDeleteRes",
      },
      {
        api: essentialMultipleImageDelete,
        name: "essentialMultipleImageDeleteRes",
      },
      { api: essentialProductList, name: "essentialProductListRes" },
      { api: essentialCustomerList, name: "essentialCustomerListRes" },
      { api: commonEssentialListDelete, name: "commonEssentialListDeleteRes" },
      {
        api: essentialCategoryProductList,
        name: "essentialCategoryProductListRes",
      },
      { api: essentialListAdd, name: "essentialListAddRes" },
      { api: sendEmail, name: "sendEmailRes" },
      { api: downloadView, name: "downloadViewRes" },
      { api: singledownloadView, name: "singledownloadViewRes" },
      { api: priceGroupSingleCustomer, name: "priceGroupSingleCustomerRes" },
      {
        api: essentialCategoryShortcutList,
        name: "essentialCategoryShortcutListRes",
      },
      { api: getCsvToJson, name: "getCsvToJsonRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { essentialDispatch } = essentialSlice.actions;
