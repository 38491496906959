import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { Flex, Input, Loader, NumberInput, Select, Text } from "@mantine/core";
import NoTableDataComponent from "components/tableNoData";
import { useDebouncedCallback } from "use-debounce";
import { Pagination } from "@mantine/core";

const CustomFooter = () => {
  return <div>Custom Footer Content</div>;
};
const TableData = ({
  data,
  pageData,
  columns,
  paginationRowsPerPageOptions,
  noDataComponent,
  selectableRows,
  onSelectedRowsChange,
  pagination,
  paginationTotalRows,
  paginationServer,
  loading,
  styleName,
  params,
  setParams,
  striped,
  fixedHeader,
  fixedHeaderScrollHeight,
  noTableHead,
  selectedRows,
  selectableRowSelected,
  selectableRowDisabled,
  noHeader,
  noHeight,
  mantinepagination,
  footer,
}) => {
  const handlePageChange = (key, value) => {
    let currentPage = params.currentPage;
    let rowsPerPage = params.rowsPerPage;

    if (key === "page") {
      currentPage = value;
    } else if (key === "per_page") {
      rowsPerPage = value;
    }

    const object = {
      ...params,
      [key]: value,
      currentPage,
      rowsPerPage,
    };

    setParams(object);
  };
  const handleChange = (value) => {
    const object = {
      ...params,
      ...value,
    };

    setParams(object);
  };

  const customSort = (selector, dir) => {
    const object = {
      ...params,
      sort_column: selector?.sortField,
      sort_order: dir,
    };
    setParams(object);
  };

  const customStyles = {
    head: {
      style: {
        fontSize: "0.70rem",
        fontWeight: 500,
      },
    },
    headRow: {
      style: {
        backgroundColor: "#E2EEFA",
        minHeight: "33px",
      },
    },
    rows: {
      style: {
        minHeight: "28px",
        maxHeight: "29px",
        borderBottom: "none",
        fontSize: "0.74rem",
        fontWeight: 400,
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "1px",
      },
      draggingStyle: {
        cursor: "move",
      },
      activeSortStyle: {
        color: "#fffff",
        "&:focus": {
          outline: "none",
        },
        "&:hover:not(:focus)": {
          color: "#fffff",
        },
      },
      inactiveSortStyle: {
        "&:focus": {
          outline: "none",
          color: "#fffff",
        },
        "&:hover": {
          color: "#fffff",
        },
      },
    },
  };

  const debounced = useDebouncedCallback((value) => {
    handlePageChange("page", value);
  }, 1000);

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (paginationServer && paginationTotalRows) {
      const pages = Math.ceil(paginationTotalRows / params?.per_page);
      setTotalPages(pages);
    }
  }, [paginationTotalRows, params?.per_page]);

  const paginationComponentOptions = {
    rowsPerPageText: (
      <>
        <Flex p={2} align={"center"}>
          <Input.Wrapper label="Go to" p={2} />
          <NumberInput
            onChange={(e) => debounced(e)}
            defaultValue={params?.page}
            hideControls
            rightSection={` of ${totalPages}`}
            min={1}
            max={totalPages}
            size="sm"
            style={{ width: "60px" }}
          />
        </Flex>
      </>
    ),
  };

  return (
    <section>
      <DataTable
        className={`${styleName} ${
          noHeight
            ? "notable-height"
            : fixedHeader
            ? "fixed-height"
            : "table-height"
        } custom-table`}
        data={data}
        footer={<CustomFooter />}
        columns={columns}
        pagination={pagination}
        customStyles={customStyles}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        noHeader={noHeader}
        onChangePage={(page) => handlePageChange("page", page)}
        onChangeRowsPerPage={(per_page) =>
          handlePageChange("per_page", per_page)
        }
        paginationTotalRows={paginationServer && paginationTotalRows}
        paginationPerPage={paginationServer && paginationRowsPerPageOptions[0]}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationServer={paginationServer}
        progressPending={loading}
        progressComponent={<Loader />}
        striped={striped}
        highlightOnHover={true}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        onSort={customSort}
        // paginationComponentOptions={paginationComponentOptions}
        paginationComponent={null}
        noDataComponent={
          <NoTableDataComponent
            columns={columns}
            text={noDataComponent ?? "No Records Available"}
          />
        }
        noTableHead={noTableHead}
        selectedRows={selectedRows}
        rowCount={paginationTotalRows}
        currentPage={params?.currentPage}
        rowsPerPage={params?.rowsPerPage}
        clearSelectedRows={true}
        selectableRowSelected={selectableRowSelected}
        selectableRowDisabled={selectableRowDisabled}
      />

      {pageData?.total > 0 ? (
        <>
          {mantinepagination && (
            <>
              {loading ? (
                ""
              ) : (
                <Pagination.Root total={paginationTotalRows}>
                  <Flex justify="flex-end" align="center" gap={2} p={10}>
                    <Select
                      style={{ width: "70px" }}
                      variant="filled"
                      data={[
                        ...paginationRowsPerPageOptions.map((option) => ({
                          value: option,
                          label: option,
                        })),
                      ]}
                      // data={[
                      //   { value: 50, label: "50" },
                      //   { value: 100, label: "100" },
                      // ]}
                      defaultValue={params?.per_page}
                      onChange={(value) =>
                        handleChange({ per_page: value, page: 1 })
                      }
                    />
                    <Text order={4} className="custom-font" weight={400} p={3}>
                      {pageData?.from}-{pageData?.to} of {pageData?.total}
                    </Text>
                    <Pagination.First
                      onClick={() => handlePageChange("page", 1)}
                      disabled={params?.page === 1}
                    />
                    <Pagination.Previous
                      onClick={() => handlePageChange("page", params?.page - 1)}
                      disabled={params?.page === 1}
                    />
                    <Pagination.Next
                      onClick={() => handlePageChange("page", params?.page + 1)}
                      disabled={params?.page === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange("page", totalPages)}
                      disabled={params?.page === totalPages}
                    />
                    <Input.Wrapper label="Go to" p={2} />
                    <NumberInput
                      onChange={(e) => debounced(e)}
                      defaultValue={params?.page}
                      hideControls
                      rightSection={` of ${totalPages}`}
                      min={1}
                      max={totalPages}
                      size="sm"
                      style={{ width: "60px" }}
                    />
                  </Flex>
                </Pagination.Root>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </section>
  );
};

export default TableData;
