import * as yup from "yup";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().required("Required"),
  confirm_password: yup
    .string()
    .required("Required")
    .test("is-valid", "Mismatch confirm password", function (value) {
      const password = this.parent.password;
      if (value !== password) {
        return false;
      }
      return true;
    }),
});

export { resetPasswordSchema };
