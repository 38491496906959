import { Logo, loginlogoImage } from 'helpers/images'
import React from 'react'

export const NotFound = () => {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <h1>404 NOT FOUND</h1>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={Logo} alt="" style={{ width: "180px", height: "180px" }} />
            </div>

        </>
    )
}
