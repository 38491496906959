import * as yup from "yup";

const employeeSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  password: yup.string().required("Required"),
  password_confirmation: yup
    .string()
    .required("Required")
    .test("is-valid", "Mismatch confirm password", function (value) {
      const password = this.parent.password;
      if (value !== password) {
        return false;
      }
      return true;
    }),
  role_id: yup.string().required("Required"),
  employee_check_id: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .test("is-valid-email", "Enter Valid Email", function (value) {
      if (value) {
        return yup
          .string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
            "Enter Valid Email"
          )
          .isValidSync(value);
      }
      return true;
    }),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  address: yup
    .mixed()
    .test("required", "Required", function (value) {
      const billing = this.parent.address;
      if (billing === "") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
});
const employeeEditSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  role_id: yup.string().required("Required"),
  employee_check_id: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .test("is-valid-email", "Enter Valid Email", function (value) {
      if (value) {
        return yup
          .string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
            "Enter Valid Email"
          )
          .isValidSync(value);
      }
      return true;
    }),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  address: yup
    .mixed()
    .test("required", "Required", function (value) {
      const billing = this.parent.address;
      if (billing === "") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
});

export { employeeSchema, employeeEditSchema };
