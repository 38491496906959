import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  //   packingCreate,
  packingList,
} from "redux/service/menuService/customerService/directpackingService";

const initialState = {
  //   packingCreateRes: { data: [], isFetching: false, error: null },
  packingListRes: { data: [], isFetching: true, error: null },
};

export const packingSlice = createSlice({
  name: "packingSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      //   { api: packingCreate, name: "packingCreateeRes" },
      { api: packingList, name: "packingListRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = packingSlice.actions;
