import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Admin login
export const authLogin = createAsyncThunk(
  "login",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/admin/login`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Admin login
export const authLogout = createAsyncThunk(
  "logout",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/admin/logout`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/employee/forgot-password`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/admin/updatePassword`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
