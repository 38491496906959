import React, {useEffect} from "react";
import {Loader} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {viewcancelorder} from "redux/service/menuService/orderService";
import OrderViewSection from "../../orders/OrderViewSection";
import BreadcrumbMenu from "common/BreadcrumbMenu";

const OrderCanceView = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const {loading, initialValues} = useSelector((state) => {
    return {
      loading: state.order.viewcancelorderRes.isFetching,
      initialValues: state.order.viewcancelorderRes.data,
    };
  });

  useEffect(() => {
    if (params?.id) {
      dispatch(viewcancelorder({id: params.id}));
    }
  }, []);

  const crumbs = [
    {title: "Dashboard", href: "/"},
    {title: "Order Management", href: "#"},
    // { title: `${formatBreadScrumbTitle(status)}`, href: `${statusHref}` },
    {title: "Cancel Order", href: "#"},
  ];

  return (
    <>
      <BreadcrumbMenu title={"Cancel Order"} crumbs={crumbs} />
      {loading ? (
        <Loader />
      ) : (
        <OrderViewSection
          title={"Cancel Order"}
          initialValues={initialValues?.data}
          view_invoice={false}
          // notVisable={false}
          notshow={true}
          printValue={"invoice"}
          enable_tax={true}
          cancelinsert={true}
          // enable_tax={true}
          // val={val}
        />
      )}
    </>
  );
};
export default OrderCanceView;
