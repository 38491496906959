import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  cardCreate,
  cardList,
  cardDelete,
} from "redux/service/menuService/customerService/cardService";

const initialState = {
  cardCreateRes: { data: [], isFetching: false, error: null },
  cardListRes: { data: [], isFetching: false, error: null },
  cardDeleteRes: { data: [], isFetching: false, error: null },
};

export const customerCardSlice = createSlice({
  name: "customerCardSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: cardCreate, name: "cardCreateRes" },
      { api: cardList, name: "cardListRes" },
      { api: cardDelete, name: "cardDeleteRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = customerCardSlice.actions;
