import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Grid, Modal, Text } from "@mantine/core";
import TextAreaField from "components/react-hook-form/TextAreaField";
import TextField from "components/react-hook-form/TextField";
import { sendEmailSchema } from "helpers/validate";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import modelStyle from "styles/style-Api/modelStyle";

const SendEmailPopup = ({
  opened,
  close,
  handleSendEmailSumbit,
  initailValue,
  resetValue,
}) => {
  const { classes } = modelStyle();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm({
    defaultValues: initailValue,
    resolver: yupResolver(sendEmailSchema),
  });

  useEffect(() => {
    if (resetValue) {
      reset();
    }
  }, [resetValue]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => close()}
        centered
        size={"xs"}
        classNames={classes}
        title={"Send Email"}
      >
        <Grid>
          <Grid.Col>
            <TextField
              formatCapital={true}
              required={true}
              label={"Subject"}
              name={"subject"}
              placeholder={"Enter Subject"}
              control={control}
            />
          </Grid.Col>
          <Grid.Col>
            <TextField
              required={true}
              label={"Email Id"}
              name={"email"}
              placeholder={"Enter Email"}
              control={control}
            />
          </Grid.Col>
          <Grid.Col>
            <TextField
              formatCapital={true}
              required={true}
              name={"customer_name"}
              label={"Customer"}
              control={control}
              placeholder={"Enter Customer Name"}
            />
          </Grid.Col>
          <Grid.Col>
            <TextField
              formatCapital={true}
              required={true}
              name={"send_by"}
              label={"Send By"}
              control={control}
              placeholder={"Enter Name"}
            />
          </Grid.Col>
          <Grid.Col>
            <Button
              loading={isSubmitting}
              onClick={handleSubmit(handleSendEmailSumbit)}
              fullWidth
            >
              Send Email
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};

export default SendEmailPopup;
