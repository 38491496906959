import React from "react";
import { DatePickerInput } from "@mantine/dates";
import { Image } from "@mantine/core";
import { datepickerIcon } from "helpers/images";
import { Controller } from "react-hook-form";
import { convertDate, convertDateTimeThree, convertDateTimeTwo, formateDate, latestCoverter } from "helpers/Golbal";
import { defaultformatdate } from "helpers/defaultTime";



const Datepickers = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  mt,
  clearable,
  variant,
  miw,
  display,
  disabled,
  readOnly,
  minDate,
  maxDate,
  onChange,
}) => {

  return (

    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePickerInput
            display={display}
            size="xs"
            disabled={disabled}
            valueFormat="YYYY-MM-DD"
            clearable={clearable}
            maw={placeholder === "recivablesummary" ? 180 : 400}
            readOnly={readOnly}
            mt={mt}
            label={label}
            variant={variant ?? "filled"}
            placeholder={placeholder}
            error={error && error.message}
            // allowSingleDateInRange={allowSingleDateInRange}
            // rightSection={<Image src={datepickerIcon} width={15} />}
            icon={<Image src={datepickerIcon} width={"1rem"} />}
            withAsterisk={required}
            onChange={(data) => {
              const date = latestCoverter(data)
              // const date = convertDateTimeThree(data)
              // const currentTime = new Date();
              // const currentHours = String(currentTime.getHours()).padStart(2, '0');
              // const currentMinutes = String(currentTime.getMinutes()).padStart(2, '0');
              // const currentSeconds = String(currentTime.getSeconds()).padStart(2, '0');
              // const combinedDateTime = new Date(`${date}T${currentHours}:${currentMinutes}:${currentSeconds}`);
              // const finalValue = defaultformatdate(combinedDateTime)
              date ? field.onChange(date) : field.onChange(null);
              onChange && onChange(date);
            }}
            
            defaultValue={field.value ? formateDate(field.value) : null}
            value={field.value ? formateDate(field.value) : null}
          
            // {...field}
            miw={miw}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      />
    </>
  );
};

export default Datepickers;
