// export const handleDownload = async (imageUrl, imageName) => {
//   console.log(imageUrl);
//   fetch(imageUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       const imageType = blob.type.split("/");
//       console.log(blob.type.split("/"));
//       const url = window.URL.createObjectURL(new Blob([blob]));
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${
//         imageName !== " " || imageName !== undefined || imageName !== null
//           ? imageName
//           : imageType[0]
//       }.${imageType[1]}`;
//       a.click();
//     })
//     .catch((error) => console.log("Error:", error));
// };

import axios from "axios";

export const handleDownload = async (fileUrl, docName) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    });
    const fileName = response?.config?.url;
    const fileExtension = fileName?.split(".").pop().toLowerCase();
    let mimeType;

    switch (fileExtension) {
      case "pdf":
        mimeType = "application/pdf";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        break;
      case "png":
        mimeType = "image/png";
        break;
      case "jfif":
        mimeType = "image/jfif";
        break;
      case "gif":
        mimeType = "image/gif";
        break;
      case "xls":
      case "xlsx":
      case "xml":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "doc":
      case "docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "txt":
        mimeType = "text/plain";
        break;
      default:
        mimeType = "application/octet-stream";
    }

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: mimeType })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = docName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};

export const handlePrintDocument = (imageUrl) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const printWindow = window.open(url);
      printWindow.onload = () => {
        printWindow.print();
      };
    })
    .catch((error) => console.log("Error:", error));
};
