import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  packingslipList,
  promotionsalesList,
  customermasterList,
  customeragingList,
  customersummaryList,
} from "redux/service/reportService/packingSlip";
const initialState = {
  packingslipListRes: { data: [], isFetching: true, error: null },
  promotionsalesListRes: { data: [], isFetching: true, error: null },
  customermasterListRes: { data: [], isFetching: true, error: null },
  customeragingListRes: { data: [], isFetching: true, error: null },
  customersummaryListRes: { data: [], isFetching: true, error: null },
};

export const packingslipSlice = createSlice({
  name: "packingslipSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: packingslipList, name: "packingslipListRes" },
      { api: promotionsalesList, name: "promotionsalesListRes" },
      { api: customermasterList, name: "customermasterListRes" },
      { api: customeragingList, name: "customeragingListRes" },
      { api: customersummaryList, name: "customersummaryListRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { reportDispatch } = packingslipSlice.actions;
