import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

//image upload
export const ImageUploadPost = createAsyncThunk(
  "image-profile-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/image-upload/${bodyParams.id}?type=${bodyParams.type}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//image +doc upload
export const ImageDocUploadPost = createAsyncThunk(
  "image-document-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/customers/${bodyParams.id}/documents`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//imageDoc view
export const ImageDocUploadView = createAsyncThunk(
  "image-document-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/customers/${bodyParams.id}/documents`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//imageDoc delete
export const ImageDocUploadDelete = createAsyncThunk(
  "image-document-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(
        `/customers/${bodyParams.customerId}/documents/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//imageDoc delete
export const SupplierImageDocUploadDelete = createAsyncThunk(
  "supplier-image-document-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(
        `/supplierdocument/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
