import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

// user-list
export const userList = createAsyncThunk(
  "user-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customer/${bodyParams.customer_id}/phone-number`,
        { params: bodyParams }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//user-create
export const userCreate = createAsyncThunk(
  "user-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `customer/${bodyParams.customer_id}/phone-number`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// user-delete
export const userDelete = createAsyncThunk(
  "user-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(
        `customer/${bodyParams.customerId}/phone-number/${bodyParams.id}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//user-patch
export const userUpdate = createAsyncThunk(
  "user-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `customer/${bodyParams.customer_id}/phone-number/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
