import {Input} from "@mantine/core";
import React from "react";
import Autocomplete from "react-google-autocomplete";
import {Controller} from "react-hook-form";

const AdressField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  page,
  disabled,
  AddressEmpty,
  AddressEditEmpty,
}) => {
  return (
    <Input.Wrapper size="xs" label={label} required={required} mx="auto">
      <Controller
        name={name}
        control={control}
        render={({field, fieldState: {error}}) => (
          <>
            <Autocomplete
              defaultValue={AddressEmpty ? "" : field.value}
              placeholder={placeholder}
              className="mantine-input-style-copy"
              options={{
                types: ["address"],
                componentRestrictions: {country: "ca"},
              }}
              apiKey={process.env.REACT_APP_API_MAP_ENDPOINT}
              onPlaceSelected={(address) => field.onChange(address)}
              onChange={(e) => {
                if (e.target.value === "") {
                  field.onChange(undefined);
                }
                if (e.target.value !== field.value) {
                  field.onChange(true);
                }
              }}
              aria-invalid={error}
              disabled={disabled}
            />
            <div className="m-1">
              {error && <Input.Error>{error.message}</Input.Error>}
              {/* {page === "Add" &&
                (field?.value === "" || field?.value?.length === 0) && (
                  <Input.Error>Required</Input.Error>
                )} */}
            </div>
          </>
        )}
      />
    </Input.Wrapper>
  );
};

export default AdressField;
