import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// brand List
export const brandList = createAsyncThunk(
  'brand-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/brand`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// single brand get
export const singleBrand = createAsyncThunk(
  'single-brand',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/brand/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


// brand Create
export const brandCreate = createAsyncThunk(
  'brand-create',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/brand`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


// brand Edit
export const brandEdit = createAsyncThunk(
  'brand-edit',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/brands`, bodyParams.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// brand Delete
export const brandDelete = createAsyncThunk(
  'brand-delete',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/brand/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// brand Status
export const brandStatus = createAsyncThunk(
  'brand-status',
  async ({ id, formData }, thunkAPI) => {
    try {
      const response = await API.put(`/brand/${id}`, formData);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
