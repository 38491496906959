import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";


// bill List
export const billList = createAsyncThunk(
  'bill-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/bills`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// bill single view
export const billSingleView = createAsyncThunk(
  'bill-single-view',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/bills/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// bill create
export const createBill = createAsyncThunk(
  'create-bill',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/bills`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// bill edit
export const editBill = createAsyncThunk(
  'update-bill',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/bills/${bodyParams.id}`, bodyParams.formData);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// bill delete
export const deleteBill = createAsyncThunk(
  'delete-bill',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/billproduct/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// bill Cost cahnge all Product
export const changeAllProductCost = createAsyncThunk(
  'change-all-bill',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/billproducts`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// bill Cost cahnge all Product
export const completeSingleBill = createAsyncThunk(
  'compelete-all-bill',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/billcomplete/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })