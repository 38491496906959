import { Button, Flex, Modal, Text } from "@mantine/core";
import { formatCurrencyComma } from "helpers/Golbal";

const ConfirmationModel = ({
  opened,
  close,
  handleSumbit,
  loading,
  label,
  title = false,
}) => {
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => close()}
        centered
        size={280}
        title={
          title && (
            <>
              <Flex gap={5}>
                <Text fz={"lg"} fw={"bold"} color="success.0">
                  Balance :
                </Text>
                <Text fz={"md"} fw={"bold"} color="font-color.0">
                  {formatCurrencyComma(title)}
                </Text>
              </Flex>
            </>
          )
        }
        withCloseButton={false}
      >
        <Text fz={"md"} fw={"normal"}>
          Are you sure you want to {label}?
        </Text>
        <Flex mt={15} justify={"space-between"}>
          <Button loading={loading} onClick={() => handleSumbit()}>
            Yes
          </Button>
          <Button onClick={() => close()} variant="outline">
            No
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default ConfirmationModel;
