import { createSlice } from '@reduxjs/toolkit'
import { tempProductApprove, tempProductDelete, tempProductList, tempProductSingleView } from 'redux/service/menuService/tempProductService'

const initialState = {
  tempProductListRes: { data: [], isFetching: true, error: null },
  tempProductDeleteRes: { data: [], isFetching: false, error: null },
  tempProductApproveRes: { data: [], isFetching: false, error: null },
  tempProductSingleViewRes: { data: [], isFetching: true, error: null },
}

export const tempProductSlice = createSlice({
  name: 'tempProductSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: tempProductList, name: "tempProductListRes" },
      { api: tempProductDelete, name: "tempProductDeleteRes" },
      { api: tempProductApprove, name: "tempProductApproveRes" },
      { api: tempProductSingleView, name: "tempProductSingleViewRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { tempProductDispatch } = tempProductSlice.actions
