export const setSessionStorage = (key, varToSet) => {
    sessionStorage.setItem(key, window.btoa(varToSet))
}
export const getSessionStorage = (key) => {
    if (typeof window !== 'undefined') {
        const getStorage = sessionStorage.getItem(key)

        try {
            return getStorage ? window.atob(getStorage) : false
        } catch (e) {
            return false
        }
    }
}
export const removeSessionStorage = (key) => {
    sessionStorage.removeItem(key)
}
export const unsetSessionStorage = () => {
    sessionStorage.clear()
}