import { createSlice } from "@reduxjs/toolkit";
import { priceDashProductUpdate } from "redux/service/menuService/priceDashBoardService";


const initialState = {
  priceDashProductUpdateRes: { data: [], isFetching: false, error: null },
};

export const priceDashBoardSlice = createSlice({
  name: "priceDashBoardSlice",
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: priceDashProductUpdate, name: "priceDashProductUpdateRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { priceDashBoardDispatch } = priceDashBoardSlice.actions;