import React from "react";
import { TextInput } from "@mantine/core";
import { Controller } from "react-hook-form";

const TextField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  disabled,
  display,
  type,
  maxLength,
  miw,
  maw,
  prefix,
  rightSection,
  size,
  variant,
  readOnly,
  onFocus,
  onChange,
  formatCapital,
  noerror,
  inputContainer,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextInput
            miw={miw}
            maw={maw}
            size={size ? size : "xs"}
            display={display}
            prefix={prefix}
            type={type}
            label={label}
            onFocus={onFocus}
            variant={variant ? variant : "filled"}
            placeholder={placeholder}
            error={noerror ? "" : error && error.message}
            rightSection={rightSection}
            icon={icon}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            withAsterisk={required}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);
            }}
            onBlur={() =>
              formatCapital &&
              field?.value &&
              field?.onChange(
                field?.value[0]?.toUpperCase() + field?.value?.substring(1)
              )
            }
            maxLength={maxLength}
            inputContainer={inputContainer}
          />
        )}
      />
    </>
  );
};

export default TextField;
