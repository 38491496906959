import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  posSummary,
  posTransactionSummary,
  downloadAllTypeReport,
  posCurrentTotalSummary,
  posCurrentCashSummary,
  posAvailableCashSummary,
  posCashInOutSummary,
  posTransactionsSummary,
} from "redux/service/reportService/posReport";
const initialState = {
  posSummaryRes: { data: [], isFetching: true, error: null },
  posTransactionSummaryRes: { data: [], isFetching: true, error: null },
  downloadAllTypeReportRes: { data: [], isFetching: true, error: null },
  posCurrentTotalSummaryRes: { data: [], isFetching: true, error: null },
  posCurrentCashSummaryRes: { data: [], isFetching: true, error: null },
  posAvailableCashSummaryRes: {data: [], isFetching: true, error: null },
  posCashInOutSummaryRes: { data: [], isFetching: true, error: null },
  posTransactionsSummaryRes: { data: [], isFetching: true, error: null },
};

export const posreportSlice = createSlice({
  name: "posreportSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: posSummary, name: "posSummaryRes" },
      { api: posTransactionSummary, name: "posTransactionSummaryRes" },
      { api: downloadAllTypeReport, name: "downloadAllTypeReportRes" },
      { api: posCurrentTotalSummary, name: "posCurrentTotalSummaryRes" },
      { api: posCurrentCashSummary, name: "posCurrentCashSummaryRes" },
      {api: posAvailableCashSummary, name: "posAvailableCashSummaryRes"},
      { api: posCashInOutSummary, name: "posCashInOutSummaryRes" },
      { api: posTransactionsSummary, name: "posTransactionsSummaryRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { reportDispatch } = posreportSlice.actions;
