import { Anchor, Breadcrumbs, Flex, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbMenu({crumbs, title}) {
  const navigate = useNavigate();
  const crumbData = crumbs.map((item, index) => (
    <Anchor
      color={crumbs.length - 1 !== index ? "#5A5A5A" : "#0094D1"}
      underline="never"
      sx={{
        cursor: index === 1 && "default",
        "&:hover": {
          textDecoration: index === 1 && "none",
        },
      }}
      href={item.href}
      key={index}
    >
      {item.title}
    </Anchor>
  ));
  return (
    <>
      <Flex align={"center"}>
        {/* <IconArrowBadgeLeftFilled
          className="pointer"
          onClick={() => navigate(-1)}
        /> */}
        <Title order={3} weight={500}>
          {title}
        </Title>
        {/* <IconArrowBadgeRightFilled
          className="pointer"
          onClick={() => navigate(1)}
        /> */}
      </Flex>
      <Breadcrumbs className="moblie-display" separator=">" fz={"sm"} mb={"lg"}>
        {crumbData}
      </Breadcrumbs>
    </>
  );
}
