import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  categoriesCreate,
  categoriesDelete,
  categoriesEdit,
  categoriesList,
  singleCategory,
  categorystatusUpdate,
} from "redux/service/menuService/catagoriesService";

const initialState = {
  categoriesListRes: { data: [], isFetching: false, error: null },
  categoriesCreateRes: { data: [], isFetching: false, error: null },
  categoriesDeleteRes: { data: [], isFetching: false, error: null },
  categorySingleRes: { data: [], isFetching: false, error: null },
  categoriesEditRes: { data: [], isFetching: false, error: null },
  categorystatusUpdateRes: { data: [], isFetching: false, error: null },
};

export const catagoriesSlice = createSlice({
  name: "catagoriesSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: categoriesCreate, name: "categoriesCreateRes" },
      { api: categoriesDelete, name: "categoriesDeleteRes" },
      { api: categoriesEdit, name: "categoriesEditRes" },
      { api: categoriesList, name: "categoriesListRes" },
      { api: singleCategory, name: "categorySingleRes" },
      { api: categorystatusUpdate, name: "categorystatusUpdateRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { catagoriesDispatch } = catagoriesSlice.actions;
