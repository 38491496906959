import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import { Provider } from 'react-redux';
import { MantineProvider } from "@mantine/core";
import configureAppStore from './store/store';
import { Notifications } from '@mantine/notifications';
import './index.css';
import './styles/fonts-new/fonts.css'
import 'react-image-upload/dist/index.css'
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { BrowserRouter as Router } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureAppStore()

root.render(
  <Router>
    <Provider store={store}>
      <MantineProvider
        theme={{
          loader: 'dots',
          fontFamily: 'Horas,Medium',
          fontFamilyMonospace: 'Horas,Medium',
          headings: { fontFamily: 'Horas,Bold' },
          colors: {
            'font-color': ['#484848', '#302F46', '#393939', '#5A5A5A', "#0094D1"],
            'success': ['#0AD27B'],
            'danger': ['#E63C3C'],
            'orange': ['#E6A23C']
          },
          components: {
            Button: {
              styles: (theme) => ({
                root: {
                  height: '1.875rem',
                  minWidth: '7rem'
                },
              })
            },

          },
          fontSizes: {
            xs: '0.65rem',
            sm: '0.70rem',
            md: '0.77rem',
            lg: '0.80rem',
            xl: '0.9rem',
          },
        }}>
        <Notifications position="top-right" zIndex={2077} />
        <App />
      </MantineProvider>
    </Provider>
  </Router>
);