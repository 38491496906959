import { createSlice } from "@reduxjs/toolkit";
import {
  authLogin,
  authLogout,
  forgetPassword,
  resetPassword,
} from "../../service/authService/authService";

const initialState = {
  loginRes: { data: [], isFetching: false, error: null },
  logoutRes: { data: [], isFetching: false, error: null },
  forgetPasswordRes: { data: [], isFetching: false, error: null },
  resetPasswordRes: { data: [], isFetching: false, error: null },
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: authLogin, name: "loginRes" },
      { api: authLogout, name: "logoutRes" },
      { api: forgetPassword, name: "forgetPasswordRes" },
      { api: resetPassword, name: "resetPasswordRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { authDispatch } = authSlice.actions;
