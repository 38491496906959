import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useInactivityLogout = (logoutTime, onLogout) => {
  useEffect(() => {
    let timer;

    const handleLogout = () => {
      onLogout();
    };

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(handleLogout, logoutTime);
    };

    const handleMouseMove = resetTimer;
    const handleKeyPress = resetTimer;

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keypress', handleKeyPress);

    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [logoutTime, onLogout]);
};

export default useInactivityLogout;