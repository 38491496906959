import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// lowstock-product
export const lowstockProductList = createAsyncThunk(
  "lowstock-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/lowstockproducts`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//overstockProductList
export const overstockProductList = createAsyncThunk(
  "overstock-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/overstockproducts`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//clearanceProductList
export const clearanceProductList = createAsyncThunk(
  "clearance-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/clearenceproduct`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
