import { createSlice } from "@reduxjs/toolkit";
import {
  lowstockProductList,
  overstockProductList,
  clearanceProductList,
} from "redux/service/menuService/inventoryService";

const initialState = {
  lowstockProductListRes: { data: [], isFetching: true, error: null },
  overstockProductListRes: { data: [], isFetching: true, error: null },
  clearanceProductListRes: { data: [], isFetching: true, error: null },
};

export const inventorySlice = createSlice({
  name: "inventorySlice",
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: lowstockProductList, name: "lowstockProductListRes" },
      {
        api: overstockProductList,
        name: "overstockProductListRes",
      },
      { api: clearanceProductList, name: "clearanceProductListRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { inventoryDispatch } = inventorySlice.actions;
