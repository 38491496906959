import React, { useEffect, useState } from "react";
import { Button, Flex, Modal } from "@mantine/core";
import { useForm } from "react-hook-form";
import AddressPopup from "./AddressPopup";
import AddSelectField from "components/react-hook-form/AddSelectField";
import { useSelector } from "react-redux";

const SelectFieldPopup = ({
  opened,
  close,
  handleAddItem,
  label,
  name,
  type,
  option,
  essentialApi,
  customer_id,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  const { customerAddressCreate } = useSelector((state) => {
    return {
      customerAddressCreate:
        state.customerAddress.customerAddressCreateRes.data,
    };
  });
  const [addAddressModel, setAddressModel] = useState({
    address_type: "",
    model: false,
  });
  useEffect(() => {
    if (
      customerAddressCreate?.data?.shipping &&
      customerAddressCreate?.data?.shipping !== ""
    ) {
      setValue("shipping_id", customerAddressCreate?.data?.shipping);
    }
  }, [customerAddressCreate]);
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => close()}
        centered
        size={280}
        withCloseButton={false}
      >
        <AddSelectField
          name={name}
          label={label}
          control={control}
          placeholder={`Select ${label}`}
          required={true}
          data={option ?? []}
          noDeleted={true}
          handleAddNewOption={() =>
            setAddressModel({
              address_type: "shipping",
              model: true,
            })
          }
        />

        <Flex mt={200} justify={"space-between"}>
          <Button loading={isSubmitting} onClick={handleSubmit(handleAddItem)}>
            Submit
          </Button>
          <Button onClick={() => close()} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
      {addAddressModel.model && (
        <AddressPopup
          name="Address"
          opened={addAddressModel.model}
          close={() => {
            setAddressModel({
              ...addAddressModel,
              model: false,
            });
          }}
          hideRadio={true}
          data={{
            address_type: addAddressModel.address_type,
          }}
          customerId={customer_id}
          billingListPage={() => essentialApi()}
        />
      )}
    </>
  );
};

export default SelectFieldPopup;
