import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

export const inventoryreportList = createAsyncThunk(
  "inventoryreport-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/inventoryreport`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const topsellingproductsList = createAsyncThunk(
  "topsellingproducts-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/topsellingproducts`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const lowstockproductsList = createAsyncThunk(
  "lowstockproducts-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/lowstockorders`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
