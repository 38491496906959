import { createSlice } from '@reduxjs/toolkit'

import { addRole, defaultRole, deleteRole, editRole, listRole, singleRoleView } from 'redux/service/settingsService/roleService';


const initialState = {
  addRoleRes: { data: [], isFetching: false, error: null },
  editRoleRes: { data: [], isFetching: false, error: null },
  listRoleRes: { data: [], isFetching: false, error: null },
  singleRoleViewRes: { data: [], isFetching: false, error: null },
  deleteRoleRes: { data: [], isFetching: false, error: null },
  defaultRoleRes: { data: [], isFetching: true, error: null },
}

export const rolesSlice = createSlice({
  name: 'RolesSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: addRole, name: "addRoleRes" },
      { api: editRole, name: "editRoleRes" },
      { api: deleteRole, name: "deleteRoleRes" },
      { api: singleRoleView, name: "singleRoleViewRes" },
      { api: defaultRole, name: "defaultRoleRes" },
      { api: listRole, name: "listRoleRes" },

    ];
    asyncActionCases.forEach((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { rolesDispatch } = rolesSlice.actions
